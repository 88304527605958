import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
// import Particles from "particles.vue3";

import '@/assets/scss/config/saas/app.scss';
import '@vueform/slider/themes/default.css';

import { initFirebaseBackend } from './authUtils'
import { configureFakeBackend } from './helpers/fake-backend';
// import {Frontegg} from "@frontegg/vue";
// import Vue from 'vue';
import * as Sentry from "@sentry/vue";


const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}


AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

const app = createApp(App);

function getSentryEnvironment() {
    let hostname = window.location.hostname;
    if (hostname.includes('dev.inkoper.io')) {
        return 'development';
    } else if (hostname.includes('test.inkoper.io')) {
        return 'production';
    } else {
        return 'development';
    }
}

Sentry.init({
    app,
    dsn: "https://5da3d3f060fdc5506919760ab7515f85@o4508211702202368.ingest.de.sentry.io/4508211703840848",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    environment: getSentryEnvironment(),
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/test\.inkoper\.io/, /^https:\/\/dev\.inkoper\.io/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    // .use(Particles)
    .use(i18n)
    .use(vClickOutside)

// app.use(Frontegg, {
//     contextOptions: {
//         baseUrl: process.env.VUE_APP_BG_BASE_URL,
//     },
//     router,
// });

app.mount('#app')